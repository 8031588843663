
// Site loader js

$(window).on('load', function () {
  $('.loader').fadeOut();
}); 

// Pop Up Video

$('.play-btn-otr').magnificPopup({
    type: 'iframe'
});

// Timer Js

$('#getting-started').countdown('2023/04/05', function(event) {
    $(".days").html(event.strftime('%d'));
    $(".hours").html(event.strftime('%H'));
    $(".minutes").html(event.strftime('%M'));
    $(".seconds").html(event.strftime('%S'));
});

// Carousel Js

$('#carousel-home-creative').owlCarousel({
    loop:true,
    items: 1,
    dots: false,
    autoplay: true,
    margin: 50,
    smartSpeed: 1000,
});

// Counter Js

$('.counter').counterUp({
    delay: 10,
    time: 1000
});

// Input Js

$(document).ready(function(){
    $(".input").focus(function(){
      $('.search-icon path').css("fill", "#6951E9");
    });
    $(".input").blur(function(){
        $('.search-icon path').css("fill", "#999999");
      });
});

// Back to Top BTN

mybutton = document.getElementById("myBtn");

// When the user scrolls down 20px from the top of the document, show the button
window.onscroll = function() {scrollFunction()};

function scrollFunction() {
  if (document.body.scrollTop > 1000 || document.documentElement.scrollTop > 1000) {
    mybutton.style.opacity = "1";
  } else {
    mybutton.style.opacity = "0";
  }
}

// Sal Animation JS

sal();


